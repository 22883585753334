const BASE_URL = process.env.REACT_APP_BASE_URL;

export const LOGIN_URL = `${BASE_URL}/connect/token`;
export const LOGOUT_URL = `${BASE_URL}/user/logout`;
export const LOGIN_URL_CODE = `${BASE_URL}/auth/Login`;
export const LOGIN_URL_PHONE = `${BASE_URL}/auth/PhoneVerify`;
export const CHANGE_USER_PERMISSION = `${BASE_URL}/user/ChangeUserPermission`;

export const GENERATE_PASSWORD = `${BASE_URL}/user/generate-password-for-register`;
export const FORGET_PASSWORD = `${BASE_URL}/user/resetpassword`;
export const GET_CUSTOMER_URL = `${BASE_URL}/user/GetUserInitialize`;
export const GENERATE_PASSWORD_FORGET = `${BASE_URL}/user/generate-password-for-reset`;
export const CHANGE_PASSWORD = `${BASE_URL}/user/passwordchange`;

//user
export const GET_USERS = `${BASE_URL}/user/GetAllUser`;
export const GET_USER_BY_ID = `${BASE_URL}/user/GetById`;
export const GET_USERS_FOR_DROPDOWN = `${BASE_URL}/user/`;
export const GET_WORKINGTYPES = `${BASE_URL}/user/dropdown-workingtypes`;
export const GET_ENGLISHLEVELS = `${BASE_URL}/user/dropdown-englishlevels`;
export const GET_USERTYPES = `${BASE_URL}/user/dropdown-usertypes`;
export const GET_PERSONALTYPES = `${BASE_URL}/user/dropdown-personaltypes`;
export const GET_WORKINGADRESS = `${BASE_URL}/user/dropdown-workingAdress`;
export const GET_CITY_FOR_DROPDOWN = `${BASE_URL}/user/get-city-for-dropdown`;
export const GET_BIRTHDAY = `${BASE_URL}/user/GetBirthDays`;


//announcement
export const GET_ANNOUNCEMENT_LIST = `${BASE_URL}/EventAnnouncement/GetAll`;
export const GET_ANNOUNCEMENT_BY_ID = `${BASE_URL}/EventAnnouncement/GetById`;
export const GET_ANNOUNCEMENT_FOR_FLASH = `${BASE_URL}/EventAnnouncement/GetEventAnnouncementForFlash?userId=2`;
export const INSERT_ANNOUNCEMENT_HISTORY = `${BASE_URL}/eventAnnouncement/InsertEventAnnouncementHistory`;
export const INSERT_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/Insert`;
export const UPDATE_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/Update`;
export const DELETE_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/Delete`;

//woman
export const GET_WOMAN_LIST = `${BASE_URL}/EventAnnouncement/GetAllWowanClub`;

//feedback
export const GET_ALL_FEEDBACK = `${BASE_URL}/EventAnnouncementHistory/GetAll`;
export const GET_FEEDBACK_BY_ID = `${BASE_URL}/EventAnnouncementHistory/GetFeedbackReportById`;
export const GET_FEEDBACK_BY_ID_FOR_SEEN = `${BASE_URL}/EventAnnouncementHistory/GetHasSeenReportById`;
export const INSERT_FEEDBACK = `${BASE_URL}/feedback/Insert`;



//Holidays
export const GET_BY_DATE_HOLIDAYS = `${BASE_URL}/publicholiday/GetByDate`;
export const GET_BY_DATE_WITH_GROUP_HOLIDAYS = `${BASE_URL}/publicholiday/GetByDateWithGroup`;


//news
export const GET_NEWS_LIST = `${BASE_URL}/EventAnnouncement/GetAllNews`;
export const INSERT_NEWS = `${BASE_URL}/EventAnnouncement/InsertNews`;
export const UPDATE_NEWS = `${BASE_URL}/EventAnnouncement/UpdateNews`;
export const DELETE_NEWS = `${BASE_URL}/EventAnnouncement/DeleteNews`;

//img
export const POST_IMG = `${BASE_URL}/storage/PostImage`;

//excel
export const GET_EXCEL = `${BASE_URL}/storage/GetExcelMenuTemplate`;
export const GET_EXCEL_SERVICE = `${BASE_URL}/storage/GetExcelServiceTemplate`;
export const GET_EXCEL_EDU = `${BASE_URL}/storage/ExportEducationReport`;
export const GET_EXCEL_SURVEY = `${BASE_URL}/storage/ExportSurveyReport`;
export const GET_EXCEL_FEED = `${BASE_URL}/storage/ExportEAFeedbackReport`;
export const GET_EXCEL_SEEN = `${BASE_URL}/storage/ExportHasSeenReport`;
export const GET_EXCEL_SPECIAL_USER = `${BASE_URL}/storage/ExportUserStepReportById`;
export const GET_EXCEL_SPECIAL = `${BASE_URL}/storage/ExportSpecialTargetReport`;

//notification
export const NOTIFICATION_LIST = `${BASE_URL}/Notification/GetAllNotifications`;
export const NOTIFICATION_CONTENT_TYPE = `${BASE_URL}/Notification/GetNotificationContentTypeQuery`;
export const NOTIFICATION_ACTIVE_EVENTS = `${BASE_URL}/EventAnnouncement/getAllActiveEventAnnouncement`;
export const NOTIFICATION_ACTIVE_USERS = `${BASE_URL}/User/GetUserCountByUserFilterQuery`;
export const NOTIFICATION_INSERT = `${BASE_URL}/Notification/Insert`;
export const NOTIFICATION_DELETE = `${BASE_URL}/Notification/Delete`;
export const UPDATE_NOTIFICATION = `${BASE_URL}/Notification/Update`;
export const GET_ALL_USER_NOTIFICATIONS_FOR_USER = `${BASE_URL}/Notification/GetAllUserNotificationsForUser`;

//phone
export const GET_PHONE = `${BASE_URL}/PhoneDirectory/GetPhoneDirectoryByTenantIdForWeb`;
export const GET_PHONE_BYID = `${BASE_URL}/PhoneDirectory/GetById`;
export const PHONE_INSERT = `${BASE_URL}/PhoneDirectory/Update`;

//menu
export const GET_ALL_MENU = `${BASE_URL}/menu/GetAllMenu`;
export const MENU_INSERT = `${BASE_URL}/menu/InsertOrUpdate`;
export const GET_WEEKLY_MENU = `${BASE_URL}/menu/GetWeeklyMenu`;
export const GET_DAILY_MENU = `${BASE_URL}/menu/GetDailyMenuByDate`;
export const GET_DAILY_MENU_BY_DATE_WITH_GROUP = `${BASE_URL}/Menu/GetDailyMenuByDateWithGroup`;

//shortcut
export const GET_ALL_SHORTCUT = `${BASE_URL}/shortcut/GetAll`;
export const GET_ALL_WITHOUT_PAGING = `${BASE_URL}/ShortCut/GetAllWithoutPaging`;
export const SHORTCUT_INSERT = `${BASE_URL}/shortcut/Insert`;
export const SHORTCUT_UPDATE = `${BASE_URL}/shortcut/Update`;
export const SHORTCUT_DELETE = `${BASE_URL}/shortcut/Delete`;
export const GET_SHORTCUT_BYID = `${BASE_URL}/shortcut/GetById`;
export const SHORTCUT_INSERT_PDF = `${BASE_URL}/shortcut/PostPdf`;
export const GET_SHORTCUT_TYPE = `${BASE_URL}/shortcut/GetAllShortCutType`;

//service
export const GET_SERVICE_TYPE = `${BASE_URL}/ServiceType/GetAll`;
export const SERVICE_INSERT = `${BASE_URL}/ServiceManagement/ServiceInsertOrUpdate`;
export const GET_ALL_SERVICE = `${BASE_URL}/ServiceManagement/GetAllService`;
export const SERVICE_TYPR_INSERT = `${BASE_URL}/ServiceType/InsertOrUpdate`;

//language
export const GET_ALL_LANGUAGE = `${BASE_URL}/language/GetAllLanguages`;
export const GET_LANGUAGE_BY_CODE = `${BASE_URL}/language/GetLanguageByCode`;
export const INSERT_LANGUAGE = `${BASE_URL}/language/Insert`;
export const UPDATE_LANGUAGE = `${BASE_URL}/language/Update`;
export const DELETE_LANGUAGE = `${BASE_URL}/language/Delete`;
export const GET_LANGUAGE_ITEMS_BY_CODE = `${BASE_URL}/localization/GetLocalizationsByLanguageCode`;
export const GET_ALL_LANGUAGE_ITEMS = `${BASE_URL}/localization/GetAllLocalizations`;
export const GET_ALL_LANGUAGE_ITEM_BY_KEY = `${BASE_URL}/localization/GetLocalizationByKey`;
export const INSERT_UPDATE_LANGUAGE_ITEM = `${BASE_URL}/localization/Insert`;

//questionnaire
export const QE_GET_ALL = `${BASE_URL}/Survey/GetAllSurveyWithDetail`;
export const QE_BASIC_GET_ALL = `${BASE_URL}/Survey/GetAllBasicSurveyList`;
export const GET_ALL_SURVEY_FOR_MOBILE = `${BASE_URL}/Survey/GetAllSurveyForMobile`;
export const SURVEY_INSERT_SURVEY_ANSWER_QUESTION = `${BASE_URL}/Survey/InsertSurveyAnswerQuestion`;
export const QE_GET_CATEGORY = `${BASE_URL}/SurveyCategory/GetAllSurveyCategory`;
export const QE_INSERT = `${BASE_URL}/Survey/Insert`;
export const QE_DELETE = `${BASE_URL}/Survey/Delete`;
export const QE_UPDATE = `${BASE_URL}/Survey/Update`;
export const QE_GET_BY_ID = `${BASE_URL}/Survey/GetSurveyById`;
export const QE_GET_ANSWER_TYPE = `${BASE_URL}/Question/GetAllAnswerType`;
export const QE_GET_ALL_BY_ID = `${BASE_URL}/Question/GetAllQuestionBySurveyId`;
export const QE_GET_REPORT = `${BASE_URL}/Survey/GetSurveyReport`;
export const QE_GET_ACTIVE = `${BASE_URL}/Survey/GetAllActiveSurvey`;

//education
export const EDU_GET_ALL = `${BASE_URL}/Education/GetAllEducation`;
// export const QE_BASIC_GET_ALL = `${BASE_URL}/Survey/GetAllBasicSurveyList`;
export const EDU_INSERT_CATEGORY = `${BASE_URL}/EducationCategory/Insert`;
export const EDU_INSERT = `${BASE_URL}/Education/Insert`;
export const EDU_DELETE = `${BASE_URL}/Education/Delete`;
export const EDU_UPDATE = `${BASE_URL}/Education/Update`;
export const EDU_CATEGORY_UPDATE = `${BASE_URL}/EducationCategory/Update`;
export const EDU_CATEGORY_DELETE = `${BASE_URL}/EducationCategory/Delete`;
// export const QE_GET_BY_ID = `${BASE_URL}/Survey/GetSurveyById`;
export const EDU_GET_CATEGORY = `${BASE_URL}/EducationCategory/GetAllForWeb`;
export const EDU_GET_ALL_CATEGORY = `${BASE_URL}/EducationCategory/GetAll`;
export const EDU_GET_BY_ID_ADMIN = `${BASE_URL}/Education/GetEducationByIdForAdmin`;
export const EDU_GET_BY_ID = `${BASE_URL}/Education/GetEducationById`;
export const EDU_CATEGORY_GET_BY_ID = `${BASE_URL}/EducationCategory/GetById`;
export const EDU_GET_REPORT = `${BASE_URL}/Education/GetEducationReport`;
export const EDU_POST_EDUCATION_QUESTIONANSWER = `${BASE_URL}/Education/InsertUserEducationQuestionAnswer`;

// export const QE_GET_REPORT = `${BASE_URL}/Survey/GetSurveyReport`;
export const EDU_GET_ACTIVE = `${BASE_URL}/Education/GetAllActiveEducation`;

//company
export const COMPANY_GET_LIST = `${BASE_URL}/ContractedCompany/GetAllContractedCompany`;
export const COMPANY_INSERT = `${BASE_URL}/ContractedCompany/Insert`;
export const COMPANY_DELETE = `${BASE_URL}/ContractedCompany/Delete`;
export const COMPANY_GET_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/GetAllContractedCompanyCategory`;
export const COMPANY_INSERT_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/Insert`;
export const COMPANY_GET_CATEGORY_ID = `${BASE_URL}/ContractedCompanyCategory/GetContractedCompanyCategoryById`;
export const COMPANY_UPDATE_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/Update`;
export const COMPANY_DELETE_CATEGORY = `${BASE_URL}/ContractedCompanyCategory/Delete`;
export const COMPANY_GET_BY_ID = `${BASE_URL}/ContractedCompany/GetContractedCompanyById`;
export const COMPANY_UPDATE = `${BASE_URL}/ContractedCompany/Update`;
export const GET_ALL_CONTRACTED_COMPANIES_FOR_MOBILE = `${BASE_URL}/ContractedCompany/GetAllContractedCompaniesForMobile`

//social
export const SOCIAL_GET_LIST = `${BASE_URL}/socialclub/GetAll`;
export const SOCIAL_GET_USER_LIST = `${BASE_URL}/user/GetUserForDropdown`;
export const SOCIAL_INSERT = `${BASE_URL}/socialclub/Insert`;
export const SOCIAL_UPDATE = `${BASE_URL}/socialclub/Update`;
export const SOCIAL_GET_ID = `${BASE_URL}/socialclub/GetById`;
export const SOCIAL_DELETE = `${BASE_URL}/socialclub/Delete`;
export const GET_ALL_ACTIVE_SOCIAL_CLUB = `${BASE_URL}/SocialClub/GetAllActiveSocialClub`;


//advertisement
export const AD_GET_ALL = `${BASE_URL}/Advertisement/GetAll`;
export const AD_GET_BY_ID = `${BASE_URL}/Advertisement/GetById`;
export const AD_INSERT = `${BASE_URL}/Advertisement/Insert`;
export const AD_UPDATE = `${BASE_URL}/Advertisement/Update`;
export const AD_DELETE = `${BASE_URL}/Advertisement/Delete`;
export const GET_AD_CATEGORY = `${BASE_URL}/AdCategory/GetAll`;
export const GET_AD_TYPE = `${BASE_URL}/AdType/GetAll`;
export const GET_ALL_ACTIVE_ADVERTISEMENT = `${BASE_URL}/Advertisement/GetAllActiveAdvertisement`;
export const GET_ALL_ACTIVE_ADVERTISEMENT_BY_USER = `${BASE_URL}/Advertisement/GetAllActiveAdvertisementByUser`;

//contentCatogry
export const GET_ALL_CONTENT_CATEGORY = `${BASE_URL}/ContentCategory/GetAllContentCategory`;

//reservation
export const GET_ALL_RESERVATION = `${BASE_URL}/Reservation/GetAllReservation`;

//emergency
export const GET_ALL_EMERGENCY = `${BASE_URL}/userEmergency/GetAllUserEmergencies`;

//stepCounter
export const GET_ALL_STEPS = `${BASE_URL}/SpecialTarget/GetAll`;
export const GET_BY_ID_STEPS = `${BASE_URL}/SpecialTarget/GetById`;
export const DELETE_STEPS = `${BASE_URL}/SpecialTarget/Delete`;
export const INSERT_STEPS = `${BASE_URL}/SpecialTarget/Insert`;
export const UPDATE_STEPS = `${BASE_URL}/SpecialTarget/Update`;
export const STEPS_REPORT = `${BASE_URL}/SpecialTarget/GetReportById`;
export const STEPS_REPORT_USER = `${BASE_URL}/SpecialTarget/GetUserReportById`;

export const GET_USER_STEP_COUNT = `${BASE_URL}/stepcounter/GetUserStepCount`;
export const GET_YEARLY_SCORE = `${BASE_URL}/stepcounter/GetYearlyScore`;
export const GET_MONTHLY_SCORE = `${BASE_URL}/stepcounter/GetMonthlyScore`;
export const GET_WEEKLY_SCORE = `${BASE_URL}/stepcounter/GetWeeklyScore`;
export const GET_DAILY_SCORE = `${BASE_URL}/stepcounter/GetDailyScore`;


//
export const PERSON_IMAGE_FILE = `${BASE_URL}/storage/user-profile`;
export const TEAM_LOGO = `${BASE_URL}/storage/team-logo`;

export const GET_WORKER_FOR_DROPDOWN = `${BASE_URL}/user/get-worker-for-dropdown`;
export const UPDATE_USER = `${BASE_URL}/user/Update`;
export const CREATE_USER = `${BASE_URL}/user/register`;
export const GET_CUSTOMER = `${BASE_URL}/user/get-customers`;
export const GET_AUTHORITYORDERS_DROPDOWN_ITEMS = `${BASE_URL}/user/dropdown-authorityorders`;
export const GET_COMMUNICATIONTYPES_DROPDOWN_ITEMS = `${BASE_URL}/user/dropdown-communicationtypes`;
export const GET_MANAGER_FOR_DROPDOWN = `${BASE_URL}/user/get-menager-for-dropdown`;

//retro
// export const GET_RETRO = `${BASE_URL}/retros`;

//role
export const GET_ACTIVE_ROLE = `${BASE_URL}/role/active-roles-for-dropdown`;
export const GET_ROLES = `${BASE_URL}/role/GetAllRoles`;
export const GET_ROLE_BY_ID = `${BASE_URL}/Role/GetById`;
export const ROLE_INSERT = `${BASE_URL}/role/Insert`;
export const ROLE_UPDATE = `${BASE_URL}/role/Update`;
export const ROLE_DELETE = `${BASE_URL}/role/Delete`;

//tenant
export const GET_TENANT = `${BASE_URL}/tenant/GetAllTenants`;
export const GET_TENANT_BY_ID = `${BASE_URL}/tenant/GetById`;
export const GET_ALL_TENANT = `${BASE_URL}/tenant/GetAllTenantList`;
export const INSERT_TENANT = `${BASE_URL}/tenant/Insert`;
export const DELETE_TENANT = `${BASE_URL}/tenant/Delete`;
export const UPDATE_TENANT = `${BASE_URL}/tenant/Update`;
export const TENANT_IMAGE = `${BASE_URL}/tenant/PostTenantLogo`;

//Permission
export const GET_PERMISSION = `${BASE_URL}/permission/GetAllPermission`;

//app
export const GET_APP = `${BASE_URL}/ProjectDownloadUrl/GetAll`;
export const GET_APP_BY_ID = `${BASE_URL}/ProjectDownloadUrl/GetById`;
export const INSERT_OR_UPDATE_APP = `${BASE_URL}/ProjectDownloadUrl/InsertOrUpdate`;
export const POST_APP = `${BASE_URL}/ProjectDownloadUrl/PostIpaFile`;
export const GET_APP_URL = `${BASE_URL}/ProjectDownloadUrl/GetDownloadUrl`;

//project
export const GET_PROJECT_UNITS = `${BASE_URL}/project/dropdown-projectunits`;
export const GET_PROJECT_FOR_DROPDOWN = `${BASE_URL}/project/dropdown-projects`;
export const GET_PROJECT = `${BASE_URL}/project`;
export const GET_PROJECT_AGREEMENT_TYPES = `${BASE_URL}/project/dropdown-projectagreementtypes`;
export const GET_PROJECT_ADMINISTRATION_TYPES = `${BASE_URL}/project/dropdown-projectadministrationtypes`;

//title
export const GET_TITLES_FOR_DROPDOWN = `${BASE_URL}/title/get-titles-for-dropdown`;
export const TITLE_LIST_URL = `${BASE_URL}/title`;

//team
export const GET_TEAM = `${BASE_URL}/team`;
export const GET_TEAM_DROPDOWN = `${BASE_URL}/team/dropdown-teams`;
export const GET_TEAM_USERS_BY_TEAMID = `${BASE_URL}/team/get-users-by-teamid`;
export const ADD_USER_TO_TEAM = `${BASE_URL}/team/team-user-add`;
export const UPDATE_USER_TO_TEAM = `${BASE_URL}/team/team-user-update`;
export const DELETE_USER_TO_TEAM = `${BASE_URL}/team/team-user-delete`;
export const GET_USER_IN_TEAM = `${BASE_URL}/team/team-user-detail`;

//company
export const GET_COMPANY_DROPDOWN_ITEMS = `${BASE_URL}/company/dropdown-companies`;
export const COMPANY = `${BASE_URL}/company`;
export const GET_COUNTRY = `${BASE_URL}/company/dropdown-country`;
export const GET_DISTRICT = `${BASE_URL}/company/dropdown-district`;
export const UPDATE_COMPANY = `${BASE_URL}/company`;
export const DISTRICT_URL = `${BASE_URL}/company/getdistrictbycityid`;
export const CITY_URL = `${BASE_URL}/company/dropdown-city`;

//authority
export const AUTHORITY_URL = `${BASE_URL}/authority`;
export const AUTHORITY_TREE = `${BASE_URL}/authority/get-authorities-tree`;

//sprint
export const SPRINT_URL = `${BASE_URL}/sprint`;
export const SPRINT_STATUS = `${BASE_URL}/sprint/dropdown-sprintstatusenum`;

//task
export const TASK_URL = `${BASE_URL}/task`;
export const TASK_TYPE_URL = `${BASE_URL}/task/dropdown-tasktype`;
export const TASK_STATUS_URL = `${BASE_URL}/task/dropdown-taskstatus`;

//comment
export const COMMENT_URL = `${BASE_URL}/comment`;

//event (retro)
export const GET_ACTIVITY = `${BASE_URL}/retro/get-activity`;
export const GET_ACTIVITY_TYPE = `${BASE_URL}/retro/dropdown-activitytype`;
export const GET_ACTIVITY_NAME = `${BASE_URL}/retro/dropdown-activityname`;
export const ACTIVITY_URL = `${BASE_URL}/retro`;
export const ACTIVITY_DETAIL_URL = `${BASE_URL}/retro/get-detail`;
export const GET_RETRO = `${BASE_URL}/retro/get-retroreport-bysprintid`;
export const POST_RETRO = `${BASE_URL}/retro/retro-report`;
export const PUT_RETRO = `${BASE_URL}/retro/retroreport-put`;

//agreement
export const GET_AGREEMENT = `${BASE_URL}/agreement`;
export const GET_PROJECT_AGREEMENTS_TYPE = `${BASE_URL}/agreement/dropdown-for-projectagreementstype`;
export const GET_PROJECT_AGREEMENTSBY_TYPE = `${BASE_URL}/agreement/dropdown-for-projectagreementstype`;
export const GET_AGREEMENT_MAINTENANCE_TYPE = `${BASE_URL}/agreement/dropdown-for-maintenancetype`;
export const GET_CURRENCYTYPE = `${BASE_URL}/agreement/dropdown-for-currencytype`;
export const GET_STAMP_TAX_TYPE = `${BASE_URL}/agreement/dropdown-for-stamptaxtype`;
export const KDV_EXEPTION_TYPE = `${BASE_URL}/agreement/dropdown-for-kdvexemptiontype`;
export const AGREEMENT_PDF_FILE = `${BASE_URL}/storage/agreement`;
export const BILLINGPLAN_URL = `${BASE_URL}/billingplan`;
export const AGREEMENT_TYPE = `${BASE_URL}/agreement/dropdown-for-agreementtype`;

//version
export const VERSION_URL = `${BASE_URL}/version`;


// used
export const EVENT_ANNOUNCEMENT_FOR_STORY = `${BASE_URL}/EventAnnouncement/GetAllEventAnnouncementForStory`;
export const DAILY_MENU = `${BASE_URL}/menu/GetDailyMenu`;
export const SERVICE_LIST = `${BASE_URL}/ServiceManagement/GetAllServiceList`;
export const SERVICE_FAVORITE_ADD_OR_DELETE = `${BASE_URL}/ServiceManagement/ServiceFavoriteAddOrDelete`
export const ACTIVE_EVENT_ANNOUNCEMENT = `${BASE_URL}/EventAnnouncement/getAllActiveEventAnnouncement`;
export const EVENT_INSERT_OR_UPDATE = `${BASE_URL}/EventAnnouncementHistory/InsertOrUpdate`
export const GET_ALL_EVENT_ANNOUNCEMENT_FOR_SLIDER = `${BASE_URL}/EventAnnouncement/GetAllEventAnnouncementForSlider`

export const INSERT_NEAR_MISS = `${BASE_URL}/nearmiss/Insert`
export const GET_ALL_NEAR_MISS = `${BASE_URL}/nearmiss/getAll`
export const GET_BY_ID_NEAR_MISS = `${BASE_URL}/nearmiss/GetById`
export const GET_ALL_ACTIVE_NEAR_MISS = `${BASE_URL}/nearmiss/GetAllActiveNearMiss`
export const GET_ALL_ACTIVE_NEAR_MISS_BY_USER = `${BASE_URL}/nearmiss/GetAllActiveNearMissByUser`
export const POST_BASE64_IMAGE_FOR_MOBILE = `${BASE_URL}/Storage/PostBase64ImageForMobile`

export const GET_NEARMISS_SUBJECT = `${BASE_URL}/nearmisssubject/GetAll`
export const GET_NEARMISS_SECTION = `${BASE_URL}/nearmisssection/GetAll`



